import React, { useState } from "react";
import { Container } from "./style"

const QuotationSummary = ({ summary, due }) => {

    return (
        <Container>
            <div className="summary-header">
                <p className="title-small">Resumo da cotação
                </p>
                <p className="title-medium">R$ {due.toLocaleString('pt-br')}</p>
            </div>

            <div className="summary-content">
                {summary.map((format, i) => {
                    return (
                        <div key={i} className="format-list">
                            <p className="label-medium">{format.formatName.toUpperCase()}</p>
                            <ul className="body-medium">
                                <li>{format.formatTypeQuantity} peça(s) de {format.formatType.toLowerCase()}</li>
                            </ul>
                        </div>)
                })}
            </div>
        </Container>
    )
}

export default QuotationSummary;