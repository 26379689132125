import React, { useState } from "react";
import TextFieldComp from "../TextFieldComp/TextFieldComp"
import { AuthForm, RequestPassPaper } from "./style"
import CustomButton from "../CustomButton/CustomButton"

const RequestResetPasswordForm = () => {
    const [authForm, setAuthForm] = useState({
        email: '',
    });

    const handleCredentials = (e) => {
        setAuthForm(authForm => ({
            ...authForm,
            [e.target.name]: e.target.value
        }));
    }
    const handleAuthentication = (e) => {
        // setAuthForm(authForm => ({
        //     ...authForm,
        //     [e.target.name]: e.target.value
        // }));
    }

    return (
        <RequestPassPaper elevation={2} >
            <p className="headline-small">Redefinir Senha</p>
            <AuthForm onSubmit={handleAuthentication}>
                <p className="title-small">Digite seu e-mail e enviaremos um link para que você possa escolher uma nova senha.</p>
                <TextFieldComp
                    name={"email"}
                    label={""}
                    value={authForm.old_password}
                    onChange={handleCredentials}
                    type={"password"}
                    fullWidth={true}
                    placeholder="seu e-mail"
                    required
                />
                <CustomButton
                    type="submit"
                    variant="contained"
                    sx={{ width: '50%' }}>
                    Entrar
                </CustomButton>
            </AuthForm>
        </RequestPassPaper>
    )
}

export default RequestResetPasswordForm;