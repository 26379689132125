import React, { useEffect, useState, useContext } from "react";
import content from "./content.json"
import { Backdrop, CircularProgress, useStepContext } from "@mui/material";
import { ResultContainer, PriceText, ActionButtons } from "./style.jsx"
import CustomButton from "../CustomButton/CustomButton.jsx";
import { BudgetContext } from "../../context/BudgetContext.jsx";

export default function PriceResult({ submitBudget, loadingBudget }) {
    const { totalPrice } = useContext(BudgetContext);
    const [priceClass, setPriceClass] = useState("large");

    useEffect(() => {
        if (totalPrice >= 1000000 && totalPrice <= 100000000) {
            setPriceClass("small")
        }
        if (totalPrice < 100000) {
            setPriceClass("large")
        }
        if (totalPrice >= 10000 && totalPrice <= 1000000) {
            setPriceClass("medium")
        }
        if (totalPrice >= 100000000) {
            setPriceClass("smallest")
        }
    }, [totalPrice])

    return (
        <ResultContainer>
            <Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={loadingBudget}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <p className={`PriceTitle`}>
                {content.price_title.pt}
            </p>

            <PriceText className={priceClass}>
                R$ {totalPrice === 0 ? "--" : totalPrice.toLocaleString()}
            </PriceText>
            <ActionButtons>
                <CustomButton
                    fullWidth={true}
                    sx={{ padding: '15px 25px' }}
                    disabled={totalPrice === 0 && "disabled"}
                    variant={totalPrice > 0 ? "outlined" : "disabled"} size="large"
                >
                    <span className="title-medium">Descartar</span>
                </CustomButton>
                <CustomButton
                    fullWidth={true}
                    sx={{ padding: '15px 25px' }}
                    disabled={totalPrice === 0 && "true"}
                    onClick={submitBudget}
                    variant={totalPrice > 0 ? "contained" : "c-disabled"} size="large"
                    color='toranja'
                >
                    <span className="title-medium">Salvar orçamento</span>
                </CustomButton>
            </ActionButtons>
        </ResultContainer >
    )
}