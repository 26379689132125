import React from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/system';
import colors from "../../utils/colors.json"

const StyledButton = styled(Button)(({ theme, color, variant, fullWidth}) => ({
  borderRadius: '50px',
  padding: '5px 20px',
  fontSize: '14px',
  boxShadow: 'none',
  textTransform: 'none',
  ...(fullWidth === "true" && {
    width: "100%"
  }),
  ...(variant === 'contained' && {
    backgroundColor: color === 'toranja'? colors.toranja : '#2B2926',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#1E1C1A',
    },
  }),
  ...(variant === 'outlined' && {
    backgroundColor: 'transparent',
    border: '2px solid #2B2926',
    color: '#2B2926',
    '&:hover': {
      backgroundColor: '#F5F5F5',
    },
  }),
  ...(variant === 'disabled' && {
    backgroundColor: 'transparent',
    border: '2px solid #b3b3b3',
    color: '#434343',
    '&:hover': {
      backgroundColor: '#F5F5F5',
    },
  }),
  ...(variant === 'c-disabled' && {
    backgroundColor: '#E6E6E6 !important',
    border: '2px solid #E6E6E6',
    color: '#434343',
    cursor: 'initial'
  }),
}));

const CustomButton = ({ variant, children, ...props }) => {
  return (
    <StyledButton variant={variant} {...props}>
      {children}
    </StyledButton>
  );
};

export default CustomButton;