import React, { useEffect, useState } from 'react';
import CustomButton from '../CustomButton/CustomButton'
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import { InputFile, ActionContainer } from './style';
import JobAssetRow from '../JobAssetRow/JobAssetRow';
import api from "../../services/api"

const AssetUpload = ({ selectedFiles, setSelectedFiles, setProgress, setUploadStatus, uploadStatus, job, modal, progress }) => {
    const [isDragging, setIsDragging] = useState(false);

    const handleFileSelect = (event) => {
        event.preventDefault();
        setIsDragging(false);

        var files;
        if (event.type === 'drop') {
            files = Array.from(event.dataTransfer.files);
        }
        if (event.type === 'change') {
            files = Array.from(event.target.files);
        }
        console.log(event)

        setSelectedFiles((prevFiles) => {
            const existingFileNames = prevFiles.map((file) => file.name);
            files.forEach((file) => {
                if (!existingFileNames.includes(file.name)) {
                    prevFiles.push(file);
                } else {
                    alert(`O arquivo ${file.name} possui o mesmo nome de um arquivo já incluido.`);
                }
            });
            return [...prevFiles];
        });
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
        setIsDragging(false);
    };

    const handleButtonClick = () => {
        document.getElementById('file-upload').click();
    };

    const changeJobStatusToReview = () => {
        var allCompaniesURL = `/Job/${job.id}/jobstatus/`
        var formData = {
            "statusId": 3
        }

        api.put(allCompaniesURL, formData)
            .then((response) => {
                return response.data;
            }).then((data) => {
                if (data === 'Atualização feita com sucesso!') {
                    setUploadStatus('DONE');
                    setTimeout(() => {
                        window.location.reload();

                    }, 500);
                }
            }).catch((error) => {
                console.log(error)
            })
    }

    const handleSendFiles = () => {
        var submitAsset = '/BlobStorage/submit-asset/'

        var formData = new FormData();
        formData.append('CompanyName', job.companyName)
        formData.append('JobId', job.id)
        formData.append('File', selectedFiles[0])

        api.post(submitAsset, formData, {
            onUploadProgress: (event) => {
                setUploadStatus('LOADING');
                let progress = Math.round(
                    (event.loaded * 100) / event.total
                );
                setProgress(progress);
            },
        }).then((response) => {
            return response.data;
        }).then((data) => {
            if (data === "Upload de arquivo realizado com sucesso!") {
                changeJobStatusToReview();
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    return (
        <InputFile
            hide={uploadStatus === "LOADING" || uploadStatus === "DONE" && `true`}
            files={selectedFiles}
            onDrop={handleFileSelect}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            isdragging={isDragging.toString()}
        >
            <VerticalAlignTopIcon />
            {isDragging ? <p className='body-large'>
                Solte o arquivo
            </p> : <p className='body-large'>
                Arraste e solte um documento para fazer o upload
            </p>}

            <input
                type="file"
                id="file-upload"
                multiple="multiple"
                style={{ display: 'none' }}
                onChange={handleFileSelect}
            />

            {selectedFiles.length === 0 ?
                !isDragging && <label htmlFor="file-upload">
                    <CustomButton
                        variant="contained"
                        onClick={handleButtonClick}
                    >
                        <span className='title-small'>Buscar no computador</span>
                    </CustomButton>
                </label> :
                <>
                    {
                        selectedFiles.map((file, i) => {
                            return (
                                <JobAssetRow key={i} job={job} blob={file} />
                            )
                        })
                    }</>
            }

            {selectedFiles.length > 0 &&
                <ActionContainer>
                    <CustomButton fullWidth={modal} onClick={handleButtonClick} variant={modal ? 'outlined' : 'contained'}>
                        <span className='title-small'>Buscar no computador</span>
                    </CustomButton>

                    {!modal &&
                        <CustomButton variant={'outlined'} onClick={handleSendFiles}>
                            <span className='title-small'>Enviar para análise</span>
                        </CustomButton>}
                </ActionContainer>}
        </InputFile>
    )
}

export default AssetUpload;

