import React, { useContext, useEffect, useState } from 'react';
import MuiAccordion from '@mui/material/Accordion';
import { styled } from '@mui/material/styles';
import AccordionSummary from './AccordionSumary.jsx';
import AccordionDetails from './AccordionDetails.jsx';
import FormatItem from './FormatItem.jsx';
import { BudgetContext } from '../../context/BudgetContext.jsx'

const CustomAccordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
    background: 'transparent',
    transition: 'all 0.5s ease',
    '&:last-child': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
    '& .Mui-expanded.MuiAccordionSummary-root': {
        background: "linear-gradient(0deg,#e8e8e816.64%,#ffffffc7.8%)"
    },
    '& .MuiCollapse-wrapper': {
        background: "linear-gradient(0deg,#e8e8e816.64%,#ffffffc7.8%)"
    },
}));

const Accordion = ({ format, id, setBudgetPayload, ...props }) => {
    const { addItemToBudget } = useContext(BudgetContext)
    const [formatBudget, setFormatBudget] = useState([]);
    const [totalPriceFormat, setTotalPriceFormat] = useState(0);

    const sumAllPrices = (list, set) => {
        var sum = 0;
        list.forEach(i => {
            try {
                sum += i.total;
            } catch (error) {
            }
        });
        set(sum);
    }

    useEffect(() => {
        if (formatBudget) {
            sumAllPrices(formatBudget, setTotalPriceFormat)
        } else {
            setTotalPriceFormat(0);
        }
        addItemToBudget(id, formatBudget)
    }, [formatBudget])

    return (
        <CustomAccordion {...props}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                <p className='title-large'> {format.name} </p>
                <p className='title-price'>R$ {totalPriceFormat}</p>
            </AccordionSummary>
            <AccordionDetails>
                <FormatItem specsList={format.specs} setFormatBudget={setFormatBudget} />
            </AccordionDetails>
        </CustomAccordion>
    )
}

export default Accordion;
