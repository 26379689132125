import React, { useState } from "react";
import TextFieldComp from "../TextFieldComp/TextFieldComp"
import { AuthForm, PasswordPaper } from "./style"
import CustomButton from "../CustomButton/CustomButton"

const PasswordForm = () => {
    const [authForm, setAuthForm] = useState({
        old_password: '',
        new_password: '',
    });

    const handleCredentials = (e) => {
        setAuthForm(authForm => ({
            ...authForm,
            [e.target.name]: e.target.value
        }));
    }
    const handleAuthentication = (e) => {
        // setAuthForm(authForm => ({
        //     ...authForm,
        //     [e.target.name]: e.target.value
        // }));
    }

    return (
        <PasswordPaper elevation={2} >
            <p className="headline-small">Redefinir Senha</p>
            <AuthForm onSubmit={handleAuthentication}>
                <TextFieldComp
                    name={"old_password"}
                    label={"Senha antiga"}
                    value={authForm.old_password}
                    onChange={handleCredentials}
                    type={"password"}
                    fullWidth={true}
                    placeholder="Digite sua senha"
                    required
                />
                <TextFieldComp
                    name={"new_password"}
                    label={"Nova senha"}
                    value={authForm.new_password}
                    onChange={handleCredentials}
                    type={"password"}
                    fullWidth={true}
                    placeholder="Digite sua nova senha"
                    required
                />
                <CustomButton
                    type="submit"
                    variant="contained"
                    sx={{ width: '50%' }}>
                    Entrar
                </CustomButton>
            </AuthForm>
        </PasswordPaper>
    )
}

export default PasswordForm;