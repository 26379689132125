import { Box } from "@mui/material";
import styled from "styled-components";
import colors from "../../utils/colors.json"

export const InputFile = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    justify-content: center;
    gap: 10px;
    border: 1.5px dashed #aaa;
    border-radius: 8px;
    padding: 50px;
    width: 100%;
    min-height: 250px;
    max-width: 500px;
    cursor: pointer;

    ${props => props.isdragging === "true" && `
        background-color: ${colors.light_gray};
        border: 1.5px dashed ${colors.gray};
    `}

    ${props => props.hide === "true" && `
        display: none;
    `}
    ${props => props.files.length > 0 && `
        max-width: 100%;
    `}
`

export const ActionContainer = styled.div`
    width:100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
`