import styled from "styled-components";
import { LoginPaper } from "../LoginForm/style";

export const PasswordPaper = styled(LoginPaper)`
    min-width: 350px;
    width: 30%;
   
`
export const AuthForm = styled.form`
    width: 100%;   
    display: flex;
    text-align: left;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap: 25px;

    p.label-large{
        width: 100%;
        margin-top: -15px;
    }
`