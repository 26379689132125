import React from "react";
import Footer from "../../components/Footer/Footer";
import RequestResetPasswordForm from "../../components/RequestResetPasswordForm/RequestResetPasswordForm";
import { UserPageContainer } from '../LoginPage/style'

const RequestResetPassword = () => {
   
    return (
        <UserPageContainer>
            <RequestResetPasswordForm />
            <Footer />
        </UserPageContainer>
    )
}

export default RequestResetPassword; 