import React from "react";
import { Dialog, Fab, DialogTitle, DialogContent, DialogActions} from "@mui/material";
import CustomButton from "../../components/CustomButton/CustomButton";
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';

const DeleteAssetModal = ({ open, handleClose }) => {

    const handleDelete = () => {
        // request delete
    }

    return (
        <Dialog
            PaperProps={{
                sx: { borderRadius: '10px', padding: '20px', gap: '20px', width: '35%' }
            }}
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <DialogTitle sx={{ padding: 0, gap: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} className="title-large">
                <Fab color="warning" disableFocusRipple disableRipple disableTouchRipple size="small"
                    sx={{ boxShadow: 'none', cursor: 'initial', color: "#fff", ":active": { boxShadow: "none" }, display: 'block' }}>
                    <ReportProblemOutlinedIcon />
                </Fab>
                Excluir item
            </DialogTitle>

            <DialogContent sx={{ padding: 0 }}>
                <p className="body-large">
                    Essa ação é irreversível. Deseja excluir o item <b>assets.zip</b>?
                </p>
            </DialogContent>

            <DialogActions sx={{ padding: 0 }}>
                <CustomButton onClick={handleClose} variant={"outlined"} fullWidth>
                    <span className="title-small">Cancelar</span>
                </CustomButton>
                <CustomButton onClick={handleDelete} variant={"contained"} fullWidth>
                    <span className="title-small">
                        Excluir
                    </span>
                </CustomButton>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteAssetModal;