import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import colors from "../../utils/colors.json"

const StrengthIndicator = styled.div`
    text-align: left;
    align-self: flex-start;
    margin-top: -10px;
    .strength-label{
        font-weight: 800 !important;
        color: ${(props) => {
        switch (props.strength) {
            case 'Forte':
                return colors.success;
            case 'Média':
                return colors.warning;
            case 'Fraca':
                return 'red';
            default:
                return colors.error;
        }
    }};
    }
`;

const PasswordStrenghChecker = ({ password }) => {
    const [strength, setStrength] = useState('');

    const evaluateStrength = (pass) => {
        if (!pass) {
            setStrength('');
            return;
        }

        const hasUpperCase = /[A-Z]/.test(pass);
        const hasLowerCase = /[a-z]/.test(pass);
        const hasNumbers = /\d/.test(pass);
        const hasSpecialChars = /[!@#$%^&*(),.?":{}|<>]/.test(pass);
        const isLongEnough = pass.length >= 8;

        if (isLongEnough && hasUpperCase && hasLowerCase && hasNumbers && hasSpecialChars) {
            setStrength('Forte');
        } else if (isLongEnough && (hasUpperCase || hasLowerCase) && (hasNumbers || hasSpecialChars)) {
            setStrength('Média');
        } else {
            setStrength('Fraca');
        }
    };

    useEffect(() => {
        evaluateStrength(password)
    }, [password])

    return (
        <StrengthIndicator strength={strength} className='title-small'>
            {strength && `Nível de segurança:`}
            {strength && <span className="strength-label"> {strength}</span>}
        </StrengthIndicator>
    )
}
export default PasswordStrenghChecker