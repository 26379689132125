import React, { useContext } from "react"
import content from './content.json';
import { Title, BudgetText, HighlightText, Instructions, InstructionsLabel } from "./style.jsx"

const CalculadoraInstructions = () => {

    return (
        <BudgetText>
            <Title>
                {content.title.pt[0]} <br />
                <HighlightText>
                    {content.title.pt[1]}
                </HighlightText>
                {content.title.pt[2]}
            </Title>

            <Instructions>
                <InstructionsLabel>{content.instructions.pt[0]}</InstructionsLabel>
                <ol>
                    {content.instructions.pt.map((instruction, i) => {
                        if (i > 0) {
                            return (<li key={i}>{instruction}</li>
                            )
                        } else {
                            return false
                        }
                    })}
                </ol>
            </Instructions>
        </BudgetText>
    )
}

export default CalculadoraInstructions;


