import React from "react";
import { Dialog, Fab, DialogTitle, DialogContent, } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import TaskAltIcon from '@mui/icons-material/TaskAlt';

const AproveBudgetFeedback = ({ open }) => {
    const { id } = useParams();

    return (
        <Dialog
            PaperProps={{
                sx: { borderRadius: '10px', padding: '20px', gap: '20px', width: '35%', minWidth: '200px', textAlign: 'center' }
            }}
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <DialogTitle sx={{ padding: 0, gap: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} className="title-large">
                <Fab color="success" disableFocusRipple disableRipple disableTouchRipple size="small"
                    sx={{
                        boxShadow: 'none', cursor: 'initial', color: "#fff",
                        ":active": { boxShadow: "none" }, display: 'flex', alignItems: 'center', justifyContent: 'center'
                    }}>
                    <TaskAltIcon />
                </Fab>
                Orçamento aprovado!
            </DialogTitle>
            <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                <p className="body-large">
                    Este orçamento estará disponível acessando a página Jobs.
                </p>
                <Link className="title-small" to={`/jobs/${id}`}>
                    Ir para Job
                </Link>
            </DialogContent>
        </Dialog>
    )
}

export default AproveBudgetFeedback;