import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: start;
    flex-direction: column;
    padding: 30px 0;
    gap: 30px;
    .loading-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }
`
export const PageHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`