import styled from "styled-components";
import colors from "../../utils/colors.json"

export const Container = styled.div`
  background-color: ${colors["pale-blue"]};
  width: 100%;

  .navbar-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${colors.carbon};
    padding: 15px;
    gap: 40px;
  }

  .icons {
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;

    .IconName{
      width: 30px;
      height: 30px;
      padding-top: 6px;
      background-color: ${colors.toranja};
      color: white;
      border-radius: 500px;
      text-align: center;
    }

    svg {
      height: 100%;
      width: auto;
      path{
        fill: ${colors.carbon};
      }
    }
  }

  .user-mail{
    display: flex;
    align-items: center;
  }

  @media screen and (min-width: 1500px){
      .Navbar {
        padding: 1.5vw 12vw;
      }
    }
`

export const Links = styled.div`
  display: flex;
  gap: 25px;
  margin-right: auto;
  font-weight: 400 !important;
  color: ${colors.gray};

  a, a:visited, a:active{
    color: black;
    text-decoration: none;
    font-weight: 400 !important;
  }

  a:hover,  a.active{
    font-weight: 500 !important;
    text-decoration: underline;
    color: ${colors.carbon};
  }

`
