import React, { useState, useEffect } from "react";
import Navbar from "./components/Navbar/Navbar";
import { Outlet } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { mainTheme } from "./themes/mainTheme"
import { BudgetProvider } from "./context/BudgetContext";
import MobileAlert from "./page/MobileAlert/MobileAlert";

const App = () => {
    const [innerHeight, setInnerHeight] = useState(window.innerHeight);
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', () => {
            setInnerHeight(window.innerHeight)
            setInnerWidth(window.innerWidth)
        })
    }, [])

    return (
        <BudgetProvider>
            {innerWidth < 800 ?
                <MobileAlert />
                :
                <ThemeProvider theme={mainTheme}>
                    <div style={{
                        padding: '20px 50px',
                        height: innerHeight + "px",
                        background: "linear-gradient(0deg, #FFFFFF 15.85%, #d3d3d39e 90.86%)"
                    }}>
                        <Navbar />
                        <Outlet />
                    </div>
                </ThemeProvider>}
        </BudgetProvider>
    );
};

export default App;
