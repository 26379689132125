import styled from "styled-components";
import colors from "../../utils/colors.json"

export const Title = styled.h1`
    font-family: 'Neue Haas Grotesk Text', 'sans-serif';
    font-weight: 700;
    width: 50%;
    color: ${colors.noir};
    font-size: 3em;
    margin: 0;

    @media (min-width: 1500px) {
        font-size: 5em;
    }
`

export const BudgetText = styled.div`
    display: flex;
    justify-content: start;
    align-self: start;
    gap: 50px;
    width: 100%;
    padding-top: 20px;
`

export const HighlightText = styled.span`
    font-family: 'Neue Haas Grotesk Text', 'sans-serif';
    font-style: italic;
    font-weight: 300;
    text-decoration: underline;
`

export const Instructions = styled.div`
    font-family: 'Neue Haas Grotesk Text', 'sans-serif';
    font-weight: 500;
    color: ${colors.noir};
    font-size: 1.4em;
    margin-top: 10px;

    @media (min-width: 1500px) {
        font-size: 1.8em;
    }

    ol {
        margin-left: 20px;
    }
`

export const InstructionsLabel  = styled.p`
    margin-bottom: 10px;
`