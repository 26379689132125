import styled from "styled-components";
import colors from "../../utils/colors.json"

export const ResultContainer = styled.div`
    position: sticky;
    width: 46%;

    .PriceTitle {
        font-family: 'Neue Haas Grotesk Text', 'sans-serif';
        font-size: 20px;
        font-weight: bold;
        line-height: 33px;
        letter-spacing: 0.005em;
    }
`

export const ActionButtons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 10px;
`

export const PriceText = styled.div`
    font-family: 'Neue Haas Grotesk Text', 'sans-serif';
    font-size: clamp(36px, 15vw, 80px);
    transition: font-size 0.3s ease;
    white-space: nowrap;
    font-weight: bold;
    font-style: normal !important;

    &.smallest {
        font-size: 4rem;
    }

    &.small {
        font-size: 5rem;
    }

    &.medium {
        font-size: 5.5rem;
    }

    &.large {
        font-size: 6.5rem;
    }

    @media (min-width: 1500px) {
        &.smallest {
            font-size: 5rem;
        }

        &.small {
            font-size: 6rem;
        }

        &.medium {
            font-size: 7.5rem;
        }

        &.large {
            font-size: 8.5rem;
        }
    }
`