import styled from "styled-components";
import colors from "../../utils/colors.json"

export const Container = styled.div`
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: start;
    flex-direction: column;
    padding: 30px 0;
    gap: 0px;
    .loading-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }
`
export const PageHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;

    .company-title{
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .headline-small{
        text-transform: capitalize;
    }

    *{
        cursor: pointer;
    }

    a{
        display: flex;
        color: ${colors.noir};
        align-items: center;
        gap: 10px;
        text-decoration: underline;
    }

    a:hover{
        font-weight: 500 !important;
    }

    a:visited, a:active{
        color: ${colors.noir};
    }
`