import React from "react";
import { Dialog, Fab, DialogTitle, DialogContent } from "@mui/material";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const AssetsSendFeedback = ({ open, handleClose }) => {

    return (
        <Dialog
            PaperProps={{
                sx: { borderRadius: '10px', padding: '20px', gap: '20px', width: '35%', minWidth: '200px', textAlign: 'center' }
            }}
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <DialogTitle sx={{ padding: 0, gap: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} className="title-large">
                <Fab color="success" disableFocusRipple disableRipple disableTouchRipple size="small"
                    sx={{
                        boxShadow: 'none', cursor: 'initial', color: "#fff",
                        ":active": { boxShadow: "none" }, display: 'flex', alignItems: 'center', justifyContent: 'center'
                    }}>
                    <TaskAltIcon />
                </Fab>
                Materiais enviados com sucesso!
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: "#000"
                    })}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                <p className="body-large">
                    Fique atento ao seu <b>e-mail</b>, enviaremos o feedback por lá!
                </p>
            </DialogContent>
        </Dialog>
    )
}

export default AssetsSendFeedback;