import React, { useState, useEffect } from "react";
import { Container, PageHeader } from "./style";
import { ArrowBack } from "@mui/icons-material";
import JobHeader from "../../components/JobHeader/JobHeader";
import { Link, useParams, useNavigate } from "react-router-dom"
import JobBody from "../../components/JobBody/JobBody";
import { Breadcrumbs, Skeleton } from "@mui/material";
import api from "../../services/api";
import { checkToken } from "../../utils/methods";
import CustomButton from "../../components/CustomButton/CustomButton";

const JobPage = () => {
    const [job, setJob] = useState(null);
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        checkToken();

        var jobWithBlobInfos = '/Job/GetJobWithBlobInfos/'
        var formData = {
            "jobId": Number(id)
        }

        api.post(jobWithBlobInfos, formData).then((response) => {
            return response.data;
        }).then((data) => {
            setJob(data)
        }).catch((error) => {
            if (error.status === 404) {
                console.log(error)
            } else {
                window.location.href = "/"
            }
        })
    }, [])

    const handleOpenPreview = () => {
        navigate("preview")
    }

    return (
        <>
            {job ?
                <Container>
                    <PageHeader>
                        {job?.status !== "Replicating" &&
                            <Breadcrumbs>
                                <Link to='/jobs'>
                                    Jobs
                                </Link>
                            </Breadcrumbs>}
                        <Link to={"/jobs"} className="title-small">
                            <ArrowBack /> Voltar
                        </Link>
                        {job?.status === "Replicating" &&
                            <CustomButton variant="contained" onClick={handleOpenPreview}>
                                <span className='title-small'> Acompanhe a produção</span>
                            </CustomButton>}
                    </PageHeader>
                    <JobHeader job={job} />
                    <JobBody job={job} />
                </Container> : <Container>
                    <PageHeader>
                        <Breadcrumbs>
                            <Link to='/jobs'>
                                Jobs
                            </Link>
                        </Breadcrumbs>
                        <Link to={"/jobs"} className="title-small">
                            <ArrowBack /> Voltar
                        </Link>
                    </PageHeader>
                    <Skeleton variant="rounded" width={"100%"} height={"10%"} />
                    <Skeleton variant="rounded" width={"100%"} height={"15%"} />
                </Container>

            }</>
    )
}

export default JobPage;