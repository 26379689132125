import React from "react";
import { Dialog, Fab, DialogTitle, DialogContent, } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import { Link } from "react-router-dom";

const BudgetSavedModal = ({ open }) => {

    return (
        <Dialog
            PaperProps={{
                sx: { borderRadius: '10px', padding: '30px 10px', gap: '20px', width: '15%', minWidth: '300px', textAlign: 'center' }
            }}
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <DialogTitle sx={{ padding: 0, gap: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} className="title-large">
                <Fab color="success" disableFocusRipple disableRipple disableTouchRipple size="small"
                    sx={{
                        boxShadow: 'none', cursor: 'initial', color: "#fff",
                        ":active": { boxShadow: "none" }, display: 'flex', alignItems: 'center', justifyContent: 'center'
                    }}>
                    <CheckIcon />
                </Fab>
                Verifique seu e-mail! <br />
                Seu orçamento foi enviado!
            </DialogTitle>
            <DialogContent>
                <Link className="body-small" to={"/budgets"}>
                    Ir para Budgets
                </Link>
            </DialogContent>
        </Dialog>
    )
}

export default BudgetSavedModal;