import React, { useState } from "react";
import { IconButton } from "@mui/material";
import CustomButton from "../../components/CustomButton/CustomButton";
import CloseIcon from '@mui/icons-material/Close';
import TextFieldComp from '../../components/TextFieldComp/TextFieldComp'
import { DialogModal, ModalTitle, ModalContent, ModalActions } from "./style"
import api from "../../services/api";

const ApproveBudgetModal = ({ open, handleClose, openFeedback, jobId }) => {
    const [jobName, setJobName] = useState('')

    const handleApprove = () => {
        handleClose();
        var changeJobStatus = `/Job/${jobId}/ChangeJobStatusToAssetsPending`;
        var formData = {
            jobName: jobName
        }
        api.put(changeJobStatus, formData).then((response) => {
            return response.data;
        }).then((data) => {
            if (data === "Status atualizado com sucesso!") {
                openFeedback();
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    return (
        <DialogModal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <ModalTitle className="title-large">
                Aprovar Budget
            </ModalTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: "#000"
                })}
            >
                <CloseIcon />
            </IconButton>
            <ModalContent>
                <TextFieldComp
                    placeholder={"Ex: Job de Digital 2024"}
                    label='Dê um nome para seu projeto:'
                    fullWidth={true} type='text'
                    value={jobName}
                    setValue={setJobName}
                />
            </ModalContent>

            <ModalActions>
                <CustomButton sx={{ minWidth: '50%' }} onClick={handleApprove} variant={"contained"}>
                    <span className="title-small">
                        Aprovar
                    </span>
                </CustomButton>
            </ModalActions>

        </DialogModal>
    )
}

export default ApproveBudgetModal;