import { styled } from 'styled-components';
import colors from "../../utils/colors.json"

export const BudgetContainer = styled.div`
    display: flex;
    flex-direction: column;
    border: 1.5px solid  ${colors.light_gray};
    border-radius: 10px;
    padding: 10px 15px;
    padding-top: 0;
    background: transparent;
    width: 100%;
    gap: 20px;
`;

export const VersionsList = styled(BudgetContainer)`
    gap: 10px;
    border: none;
    padding: 0;
`;

export const HeaderSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: end;
    height: 60px;
    border-bottom: 1.5px solid ${colors.light_gray};
    width: 100%;

    .title-container {
        font-weight: 700 !important;
        border-bottom: 1.5px solid black;
        height: 50%;
        padding: 0px 5px;
    }

    .header-right{
        display: flex;
        align-items: center;
        height: 100%;
        gap: 15px;

        a{
            display: flex;
            align-items: center;
            gap: 5px;
            color: #000;
        }
    }

    button{
        align-self: center;
    }
`;

export const AssetRow = styled.div`
    background-color:  ${colors.light_gray_2};
    border-radius: 10px;
    border: none;
    margin: 0;
    width: 100%;
    padding: 5px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span{
        margin: 0 !important;
    }

    .action-row{
        display: flex;
        align-items: center;

        svg{
            fill: ${colors.noir};
        }
    }
`;