import React, { useState } from 'react';
import { BudgetContainer, HeaderSection } from './style'
import SuccessSnackBar from '../SuccessSnackBar/SuccessSnackBar';
import { Link } from 'react-router-dom';
import { VerticalAlignBottom } from '@mui/icons-material';
import AssetsList from "../AssetsList/AssetsList"
import CustomButton from '../CustomButton/CustomButton';
import UploadModal from '../../modals/UploadModal/UploadModal';

const JobBody = ({ job }) => {
    const [open, setOpen] = useState(false);
    const [openUploadModal, setOpenUploadModal] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);

    const handleOpenUploadModal = () => {
        setOpenUploadModal(true);
    }

    const handleButtonClick = () => {
        document.getElementById('file-upload').click();
    };

    const handleFileSelect = (event) => {
        event.preventDefault();

        var files;
        if (event.type === 'drop') {
            files = Array.from(event.dataTransfer.files);
        }
        if (event.type === 'change') {
            files = Array.from(event.target.files);
        }
        console.log(event)

        setSelectedFiles((prevFiles) => {
            const existingFileNames = prevFiles.map((file) => file.name);
            files.forEach((file) => {
                if (!existingFileNames.includes(file.name)) {
                    prevFiles.push(file);
                } else {
                    alert(`O arquivo ${file.name} possui o mesmo nome de um arquivo já incluido.`);
                }
            });
            return [...prevFiles];
        });

        handleOpenUploadModal();
    };

    return (
        <BudgetContainer variant='outlined'>
            <HeaderSection>
                <div className='title-container'>
                    <p p='title-small'>Assets</p>
                </div>

                <div className='header-right'>
                    {/* <Link className='title-small'>
                        Budget aprovado
                        <VerticalAlignBottom fontSize='small' />
                    </Link> */}
                    {/* <Link className='title-small'>Baixar tudo
                        <VerticalAlignBottom fontSize='small' />
                    </Link> */}

                    {(job?.status === "Assets Pending" || job?.status === "Assets Review") &&
                        <CustomButton onClick={handleButtonClick} variant="outlined">
                            <span className='title-small'> Fazer upload</span>
                        </CustomButton>}

                    <input
                        type="file"
                        id="file-upload"
                        multiple="multiple"
                        style={{ display: 'none' }}
                        onChange={handleFileSelect}
                    />
                </div>
            </HeaderSection>

            <AssetsList job={job} blob={job.blob} />

            <UploadModal
                job={job}
                open={openUploadModal}
                handleClose={() => { setOpenUploadModal(false) }}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
            />
            <SuccessSnackBar open={open} setOpen={setOpen} />
        </BudgetContainer>
    );
};

export default JobBody;
