import React, { useEffect, useState } from "react"
import { StyledTableRow, StyledTableCell } from "../DefaultTable/DefaultTable"
import { useNavigate } from 'react-router-dom';

const BudgetListRow = ({ budget }) => {
    const navigate = useNavigate();
    const [isEmail, setIsEmail] = useState(false);

    const handleOpenBudget = () => {
        navigate(budget.id.toString());
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const handleEmailChange = (string) => {
        if (emailRegex.test(string)) {
            setIsEmail(true);
        } else {
            setIsEmail(false);
        }
    };

    useEffect(() => {
        handleEmailChange(budget.client);
    }, [])

    return (
        <StyledTableRow>
            <StyledTableCell onClick={handleOpenBudget} className='body-large' variant='no-outline'>{`Budget Nº` + budget.jobDescription}</StyledTableCell>
            <StyledTableCell onClick={handleOpenBudget} className='body-large' variant='no-outline'>{budget.createdAt}</StyledTableCell>
        </StyledTableRow>
    )
}

export default BudgetListRow;