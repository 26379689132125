import React, { useState } from 'react';
import { InfoBox, BudgetContainer } from './style'
import CustomButton from '../CustomButton/CustomButton';
import ApproveBudgetModal from '../../modals/ApproveBudgetModal/ApproveBudgetModal';
import AproveBudgetFeedback from '../../modals/AproveBudgetFeedback/AproveBudgetFeedback';

const BudgetHeader = ({ budget }) => {
    const [approveModal, setApproveModal] = useState(false);
    const [openAprovedFeedback, setOpenAprovedFeedback] = useState(false);

    const handleApproveModal = () => {
        setApproveModal(true);
    }

    return (
        <BudgetContainer variant={"outlined"}>
            <InfoBox>
                <p className='label-medium'>
                    {budget.createdAt}
                </p>
                <p className='headline-small'>
                    Budget Nº {budget.name}
                </p>
            </InfoBox>

            <CustomButton variant="contained" onClick={handleApproveModal}>
                <span className='title-small'> Aprovar budget</span>
            </CustomButton>

            <ApproveBudgetModal jobId={budget.jobId} open={approveModal} handleClose={() => { setApproveModal(false) }} openFeedback={() => { setOpenAprovedFeedback(true) }} />
            <AproveBudgetFeedback open={openAprovedFeedback} handleClose={() => { setOpenAprovedFeedback(false) }} />
        </BudgetContainer>
    );
};

export default BudgetHeader;
