import React, { useState, useEffect } from 'react';
import AssetUpload from '../AssetUpload/AssetUpload';
import { AssetsContainer, ActionContainer } from './style';
import JobAssetRow from '../JobAssetRow/JobAssetRow';
import CustomButton from '../CustomButton/CustomButton';
import ProgressBar from '../ProgressBar/ProgressBar';
import AssetsSendFeedback from '../../modals/AssetsSendFeedback/AssetsSendFeedback';

const AssetsList = ({ blob, job }) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [progress, setProgress] = useState(0)
    const [uploadStatus, setUploadStatus] = useState('INIT')
    const [openSendFeedback, setOpenSendFeedback] = useState(false)

    useEffect(() => {
        if (selectedFiles.length > 0) {
            console.log(selectedFiles)
        }
    }, [selectedFiles])

    return (
        <AssetsContainer>
            {blob.length > 0 && blob.map((blobItem, i) => {
                return <JobAssetRow key={i} job={job} blob={blobItem} />
            })}

            {selectedFiles.length === 0 && blob.length === 0 ?
                <><p className="headline-small">Faça o upload dos materiais do seu job</p>
                    <p className="label-large">Certifique-se de incluir sua RM e linhas criativas.</p> </> : null}

            {blob.length === 0 &&
                <AssetUpload job={job} uploadStatus={uploadStatus} selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} setProgress={setProgress} setUploadStatus={setUploadStatus} />
            }
            {uploadStatus === "LOADING" || uploadStatus === "DONE" &&
                <>
                    <p className="headline-small">{progress < 100 ? 'Enviando seus materiais...' : 'Feito!'}</p>
                    <ProgressBar progress={progress} />
                </>
            }

            {uploadStatus === 'DONE' &&
                <AssetsSendFeedback open={openSendFeedback} handleClose={() => { setOpenSendFeedback(false) }} />}
        </AssetsContainer>
    )
}

export default AssetsList;

