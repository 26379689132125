import React, { useState, useEffect } from 'react';
import { BudgetContainer, Details, HeaderSection } from './style'
import QuotationSummary from '../QuotationSummary/QuotationSummary'
import BudgetTerms from '../BudgetTerms/BudgetTerms'
import { VerticalAlignBottom } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import api from '../../services/api';

const BudgetBody = ({ budget }) => {
    const [version, setVersion] = useState(null)

    const handleGetVersions = () => {
        var getVersionsByBudgetBase = '/Budget/GetBudgetVersionsByBudget'
        var formData = { baseBudgetId: budget.baseBudgetId }

        api.post(getVersionsByBudgetBase, formData).then((response) => {
            return response.data;
        }).then((data) => {
            data.forEach((item) => {
                if (item.isActive === true) {
                    setVersion(item)
                }
            })
        }).catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {
        if (budget) {
            handleGetVersions();
        }
    }, [budget])

    return (
        <>{version &&
            <BudgetContainer variant='outlined'>
                <HeaderSection>
                    <div className='title-container'>
                        <p className='title-small'>Versão {version.version}{"   "}|{"   "}{version.createdAt}</p>
                    </div>
                    {/* <Link className='title-small'>
                        Baixar carta
                        <VerticalAlignBottom fontSize='small' />
                    </Link> */}
                </HeaderSection>

                <Details>
                    <QuotationSummary summary={version.budgetResume} due={version.due} />
                    <BudgetTerms terms={version.terms} />
                </Details>
            </BudgetContainer>}
        </>
    );
};

export default BudgetBody;
