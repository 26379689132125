import styled from "styled-components"
import colors from "../../utils/colors.json"

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    background-color: ${colors.light_gray_2};
    height: 100vh;
    gap: 20px;
    text-align: center;
    padding: 40px 20px;
    box-sizing: border-box;

    .link-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 50px;
        margin-top: auto;
        margin-bottom: auto;
    }

    a{
        text-decoration: none;
        width: 35px;

        svg{
            width: 100%;
            height: auto;
        }
    }

    .r-icon{
        width: 5em;
        fill: ${colors.noir};
    }
    .social-icons{
        display: flex;
        gap: 15px;
    }
`