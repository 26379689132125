import styled from "styled-components"
import { Paper } from '@mui/material';

export const PaperCustom = styled(Paper)`
    min-width: 40%;
    border-radius:10px!important;
    border-color:white;
    border: 1px solid #b3b3b3 !important;
    max-height:100%;
    overflow-y:auto;
    transition:all 0.5s ease;
`