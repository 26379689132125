import { styled } from 'styled-components';
import colors from "../../utils/colors.json"
import { AccordionDetails } from '@mui/material';

export const BudgetContainer = styled.div`
    display: flex;
    flex-direction: column;
    border: 1.5px solid  ${colors.light_gray};
    border-radius: 10px;
    padding: 10px 15px;
    padding-top: 0;
    background: transparent;
    width: 100%;
    gap: 20px;
`;

export const VersionsList = styled(BudgetContainer)`
    gap: 10px;
    border: none;
    padding: 0;
`;

export const HeaderSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: end;
    height: 70px;
    border-bottom: 1.5px solid ${colors.light_gray};
    width: 100%;

    .title-container {
        border-bottom: 1.5px solid black;
        height: 50%;
        padding: 0px 5px;
    }

    button{
        align-self: center;
    }

    a{
        height: 100%;
        display: flex;
        align-items: center;
    }
`;

export const Details = styled(AccordionDetails)`
   display: flex;
   flex-direction: column;
   gap: 20px;
   padding-top: 0 !important;
`;
