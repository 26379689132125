import styled from "styled-components"
import colors from "../../utils/colors.json"

export const BudgetSection = styled.div`
    width: 100%;
    padding: 0px 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    height: 90%;

  ::-webkit-scrollbar {
      width: 6px;
  }

  ::-webkit-scrollbar-track {
      background-color: rgb(255, 255, 255);
      border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
      background-color: ${colors.light_gray} ;
      ;
      border-radius: 2px;
  }

  ::-webkit-scrollbar-thumb:hover {
      background-color: #333333;
  }

  ::-webkit-scrollbar-thumb:active {
      background-color: #1a1a1a;
  }
`

export const FormatsContainer = styled.div`
    position: relative;
    display: flex;
    align-items: end;
    justify-content: space-between;
    width: 100%;
    min-height: 100px;
    height: 50vh;
`