import React from 'react';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import colors from "../../utils/colors.json";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        {...props}
        expandIcon={<ArrowForwardIcon sx={{
            fontSize: '2rem',
            color: colors.toranja,
            '@media (max-width: 768px)': {
                fontSize: '1.2rem',
            }
        }} />}
    />
))(({ theme }) => ({
    backgroundColor: 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    gap: 50,
    '@media (max-width: 600px)': {
        gap: 10,
    },  
    '@media (min-width: 1500px)': {
        paddingTop: 15,
        paddingBottom: 15,
    },
    paddingTop: 5,
    paddingBottom: 5,
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(-90deg)',
        color: colors.noir
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
        // transform: 'rotate(90deg)',
        color: colors.toranja
    },
    '& .MuiAccordionSummary-content': {
        // marginLeft: theme.spacing(1),
        justifyContent: 'space-between',
    },
    
    ...theme.applyStyles('light', {
        backgroundColor: 'transparent',
    }),
}));

export default AccordionSummary;
