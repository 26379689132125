import React, { useState } from 'react';
import { InfoBox, JobHeaderContainer, StatusContainer } from './style'
import { IconButton } from '@mui/material';
import EditOutlined from "@mui/icons-material/EditOutlined";
import Status from "../Status/Status"
import EditJobNameModal from '../../modals/EditJobNameModal/EditJobNameModal';

const JobHeader = ({ job }) => {
    const [openEditModal, setOpenEditModal] = useState(false);

    return (
        <JobHeaderContainer variant={"outlined"}>
            <InfoBox>
                <p className='headline-small'>
                    {job.name} {" "}
                    {/* <IconButton onClick={() => { setOpenEditModal(true) }}>
                        <EditOutlined sx={{ color: '#000' }} />
                    </IconButton> */}
                </p>
            </InfoBox>

            <StatusContainer>
                <Status status={job.status} />
            </StatusContainer>

            {/* <EditJobNameModal open={openEditModal} handleClose={() => { setOpenEditModal(false) }} name={'Dia dos Namorados'} /> */}
        </JobHeaderContainer>
    );
};

export default JobHeader;
