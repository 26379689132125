import React, { useState } from "react";
import { Container, AddButton } from "./style"
import TermRow from "../TermRow/TermRow"

const BudgetTerms = ({terms}) => {

    return (
        <Container>
            <div className="terms-header">
                <p className="title-small">Legal / Cláusulas</p>
            </div>

            <div className="terms-content">
                {terms.map((t, i) => {
                    return <TermRow id={i} key={i} description={t.description} text={t.text} />
                })}
            </div>
        </Container>
    )
}

export default BudgetTerms;