import React, { useState, useEffect } from "react";
import { Container, PageHeader } from "./style";
import { ArrowBack } from "@mui/icons-material";
import { Link, useParams } from "react-router-dom"
import CustomButton from "../../components/CustomButton/CustomButton";
import api from "../../services/api";
import { checkToken } from "../../utils/methods";
import {Check, VerticalAlignBottom} from '@mui/icons-material';

const PreviewPage = () => {
    const [job, setJob] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        checkToken();

        var jobWithBlobInfos = '/Job/GetJobWithBlobInfos/'
        var formData = {
            "jobId": Number(id)
        }

        api.post(jobWithBlobInfos, formData).then((response) => {
            return response.data;
        }).then((data) => {
            setJob(data)
        }).catch((error) => {
            if (error.status === 404) {
                console.log(error)
            } else {
                window.location.href = "/"
            }
        })
    }, [])

    const handleApprove = () => {
        var allCompaniesURL = `/Job/${job.id}/jobstatus/`
        var formData = {
            "statusId": 5
        }

        api.put(allCompaniesURL, formData)
            .then((response) => {
                return response.data;
            }).then((data) => {
                if (data === 'Atualização feita com sucesso!') {
                    window.location.reload();
                }
            }).catch((error) => {
                console.log(error)
            })
    }

    const handleDownload = () => {}

    return (
        <Container>
            <PageHeader>
                <Link to={"/jobs"} className="title-small">
                    <ArrowBack /> Voltar
                </Link>
                {job?.status === "Replicating" &&
                    <CustomButton endIcon={<Check />} variant="contained" onClick={handleApprove}>
                        <span className='title-small'> Aprovar </span>
                    </CustomButton>}
                {job?.status === "Delivered" &&
                    <CustomButton endIcon={<VerticalAlignBottom />} variant="outlined" onClick={handleDownload}>
                        <span className='title-small'> Baixar peças </span>
                    </CustomButton>}
            </PageHeader>

            <iframe style={{ border: "none" }}
                width="100%"
                height="100%"
                src="https://preview.campaigndesigner.io/creativeBuilder/146643/b4db677bb03a51582a8e8028e6b23f6a58b97a26c7aa28bf3ba2703cd31bee05eb2b2240a2c3114fb377e030ed4f18f4b250c20eec55ba5213b874f692730eb3?language=PT" allowfullscreen>
            </iframe>
        </Container>
    )
}

export default PreviewPage;