import { Box } from "@mui/material";
import styled from "styled-components";

export const AssetsContainer = styled.div`
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap: 10px;
    /* min-height: 40vh; */

    .label-large{
        margin-bottom: 10px;
    }
`